import * as React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import styled from "styled-components";
import { Trans } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby"


// styles
const Page404 = styled.section`
position: relative;
display: block;
overflow: hidden;
color: #333;
padding: 80px 25% 20px 25%;
`
const Paragraph = styled.p`
color: #ccc;
font-size: 10pt;`

// markup
const NotFoundPage = () => {

  return (
    <Layout >
            <Page404>
                <h1><Trans>Page404.404</Trans></h1>
                <Paragraph><Link to="/"><Trans>Page404.Text</Trans></Link></Paragraph>
            </Page404>
        </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;